<script setup lang="ts">
import { UIIcon } from '@groover-dev/groover-ui'

/**
 * Props for the LogoIconFramed component
 *
 * @param size - Size of the component.
 *   'sm' is 24x24 with a "sm" size icon (16x16).
 *   'base' is 32x32 with a "base" size icon (20x20).
 *   'lg' is 40x40 with a "lg" size icon (24x24).
 * @param iconSizeOverride - If provided, explicitly sets the size of the logo within the container.
 */
type Props = {
  size?: 'base' | 'sm' | 'lg'
  iconSizeOverride?: InstanceType<typeof UIIcon>['size']
}

const props = withDefaults(defineProps<Props>(), {
  size: 'base',
  iconSizeOverride: undefined,
})

const containerCssClasses = computed(() => {
  return props.size === 'lg'
    ? 'tw-size-4xl'
    : props.size === 'sm'
      ? 'tw-size-2xl'
      : 'tw-size-3xl'
})
</script>

<script lang="ts">
/**
 * Displays the Groover logo (without text) in a circular frame with a shadow.
 */
export default {}
</script>

<template>
  <div
    class="tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-surface tw-shadow"
    :class="containerCssClasses"
  >
    <UIIcon
      name="groover:logo"
      :size="iconSizeOverride ?? size"
      class="tw-text-icon-brand"
    />
  </div>
</template>
